import './styles/globals.css'
import '@rainbow-me/rainbowkit/styles.css'
import './App.css';

import toast, { Toaster }from 'react-hot-toast'
import { useAccount } from 'wagmi';
import { mainnet, goerli } from 'wagmi/chains'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { useState, useEffect } from 'react';
import Loader from './Loader';
import { encode as base64_encode } from 'base-64';
import ShortUniqueId from 'short-unique-id';
import {fetchData , putData} from './ddb';
import TagInput from './components/Tags';
import { ThemeProvider } from 'styled-components'
import { Heading, Button, ThorinGlobalStyles, lightTheme, Spinner, darkTheme} from "@ensdomains/thorin";
import Header from './components/header';
import DecryptFile from './components/decryptFile';
import humanFileSize from './components/fileSize';
import {
  Routes,
  Route
} from 'react-router-dom'

// import {ENS} from "@ensdomains/ensjs";
// import { CryptoJS } from 'crypto-js'
var AES = require("crypto-js/aes");
require('dotenv').config()
const IPFS = require('ipfs-api');
const uid = new ShortUniqueId({ length: 10 });

let secrets = process.env.REACT_APP_INFURA_PROJECT_ID + ':' + process.env.REACT_APP_INFURA_PROJECT_SECRET;
let encodedSecrets = base64_encode(secrets);

const ipfs = new IPFS({
  host: 'ipfs.infura.io', port: 5001, protocol: 'https', headers: {
    Authorization: 'Basic ' + encodedSecrets
  }
});

const { chains, provider } = configureChains(
  // [mainnet],
  [goerli],
  [infuraProvider({}), publicProvider()]
)

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains,
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

function App() {
  const { address, connector, isConnected } = useAccount()
  const [theme, setTheme] = useState(lightTheme);
  const [buf, setBuf] = useState();
  const [hash, setHash] = useState("");
  const [loader, setLoader] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [link, setLink] = useState("");
  const [tags, setTagsData] = useState([]);
  const [decryptVisible, setDecryptVisible] = useState(true);
  const [fileSize, setFileSize] = useState("");
  const [fileName, setFileName] = useState();

  // const [fetchResult, setFetchResult] = useState("");
  useEffect(()=>{
    console.log("++here @@@@@")
    toast(() => (
      <span>
        We support sharing <b>subname</b> now! Try typing things like <b>*.org3.eth</b> in step 2
      </span>
    ), {
      icon: '👏',
      duration: 5000
    });
    // do stuff here...
  }, [])
  const captureFile = (event) => {
    event.stopPropagation()
    event.preventDefault()
    
    const file = event.target.files[0]
    setFileSize(file.size);
    setFileName(file.name);
    let reader = new window.FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = () => convertToBuffer(reader)
  };

  const convertToBuffer = async (reader) => {
    //file is converted to a buffer to prepare for uploading to IPFS
    const buffer = await Buffer.from(reader.result);
    setBuf(buffer);
  };

  const addFileToDynamoDB = async (_owner, _ipfsId) => {
    let unique_link = uid();
    setLink(unique_link);
    const data = {
      file_id: unique_link,
      file_owner: _owner,
      file_hash: _ipfsId,
      file_name: fileName,
      permissionList: tags,
    }
    await putData('doc3' , data);

  };
  const CopyShare = async (text) => {
    try {
        await navigator.clipboard.writeText(text)
        toast.success('Link copied to clipboard')
    } catch (err) {
        console.error('Failed to copy, please try again.', err)
        toast.error('Failed to copy, please try again.')
    }
}
  const addEncryptedFileToDynamoDB = async (_owner, _ipfsId) => {
    let unique_link = uid();
    setLink(unique_link);
    const data = {
      file_id: unique_link,
      file_owner: _owner,
      file_hash: _ipfsId,
      file_name: fileName,
      permissionList: tags, 
      encrypted: true,
      network: "goerli",
    }
    await putData('doc3' , data);
  };

  const fetchDataFormDynamoDb = () => {
    console.log(address);
    console.log(isConnected);
    fetchData('doc3');
  }

  const btnOnClick = async (event) => {
    if(isConnected === false){
      toast.error('Connect Wallet First');
    }
    if(!buf){
      toast.error('Please choose file first');
      return;
    }
    if(tags.length === 0){
      toast.error('Sharing list cannot be vacant. Remember to hit ENTER button after typing!')
      return;
    }
    event.preventDefault();
    setLoader(true);
    let ipfsId
    const buffer = buf
    await ipfs.add(buffer)
      .then((response) => {
        ipfsId = AES.encrypt(JSON.stringify(response[0].hash), process.env.REACT_APP_ENCRYPTION_KEY).toString();
        setHash(ipfsId);
        addEncryptedFileToDynamoDB(address, ipfsId);
      }).catch((err) => {
        console.error(err)
        alert('An error occurred. Please check the console');
      })
    if (ipfsId){
      setShowLinks(true)
      setBuf();
    }
    else{
      setShowLinks(false)
    }
    setLoader(false);
  }
  if (loader) {
    return (
      <div className="container container--flex">
        <Loader />
        {/* <Spinner/> */}
      </div>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
       <ThorinGlobalStyles />
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains}>
     {
    <div style={{minHeight: "100vh"}}>
      <Header position="sticky" />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="container container--flex">

          <Heading
            as="h1"
            level="1"
            align="center"
            style={{  marginBottom: '2rem', lineHeight: '1'}}
          >
            Simple File Sharing with Any Ethereum Wallet
          </Heading>
      {
        showLinks ? <></>:
        <div>
          <div className="container--small">
          <h4>     
            <svg width="50" height="50">
            <circle cx="25" cy="25" r="20" fill="rgb(82, 152, 255)" />
            <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="100px" font-family="Arial" dy=".3em">1</text>
            Sorry, your browser does not support inline SVG.
          </svg> Choose one file to upload
          </h4>
          <input type="file" onChange={captureFile} id="files" required style={{margin: "auto", font: "18px"}} class="hidden" title="123"/>

          {fileSize === "" ? <></> : <p>file size: {humanFileSize(fileSize)}</p>}
          </div>

          <div className="container--small">
            <h4>     
              <svg width="50" height="50">
              <circle cx="25" cy="25" r="20" fill="rgb(82, 152, 255)" />
              <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="100px" font-family="Arial" dy=".3em">2</text>
              Sorry, your browser does not support inline SVG.
            </svg> Enter the people that you want to share this file to
            </h4>
            <p>Type ENS (julieshi.eth) or ETH addresses and press enter!</p>
          <TagInput tags={[]} setTags={(t) => {setTagsData(t)}}/>
          </div>

          <div className="container--small">
          <Button type="button" onClick={btnOnClick}>Upload</Button>
          </div>
        </div>
      }

      {
        showLinks ?
          <div>
            <div className="container--small">
              <h4>     
                <svg width="50" height="50">
                <circle cx="25" cy="25" r="20" fill="rgb(82, 152, 255)" />
                <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="100px" font-family="Arial" dy=".3em">3</text>
                Sorry, your browser does not support inline SVG.
              </svg> Share your file link: <br></br><div style={{color:"rgb(82, 152, 255)", cursor : "pointer"}} onClick={CopyShare(window.location.origin + '/' + link)}>{window.location.origin}/{link} </div>
              </h4>
              <p>Only people you granted access to will be able to access this file. They cannot see where this file is saved in IPFS either.</p>
              {/* <p>Sharing this to: {tags.length ? tags: <>None</>} </p>      */}
            </div>
            {/* <h6>IPFS Hash: {hash}</h6> */}
            
            {/* <a href={"https://ipfs.io/ipfs/" + hash}>Clickable Link to view file on IPFS</a> */}
          </div> :
          <p></p>
      }
      {
        showLinks ? 
        <div className="container--small">
        <Button type="button" onClick={() => setShowLinks(false)}>Share another file!</Button> 
        </div> : <></>
        
      }
      
      <p>DM <a href="https://twitter.com/julieshi_eth">@julieshi_eth</a> for questions or feedback</p>
      </div>
      <Routes>
        {/* <Route path="/" element={} /> */}
        <Route path=":file_id" element={<DecryptFile visible={decryptVisible} setVisible={setDecryptVisible} current_address={address}/>} />
      </Routes>
      </div>
      }
      </RainbowKitProvider>
      </WagmiConfig>
      </ThemeProvider>
  );
}

export default App;
